import React, { useLayoutEffect, useRef, useState } from 'react';
import { Avatar, Button, Icon } from 'monday-ui-react-core';
import parse from 'html-react-parser';
import { RepliesList } from '../RepliesList/RepliesList';
import { TimeView } from '../TimeView/TimeView';
import { DropdownChevronDown, ExternalPage } from 'monday-ui-react-core/dist/allIcons';
import { Reply } from '../../types';
import { MAX_POST_HEIGHT } from '../../constants';
import { appStore } from '../../stores/app.store';
import './Post.scss';

export const Post: React.FC<PostData> = ({ personName, personImage, createTime, body, replies, id }) => {
  const postsRef = useRef<HTMLDivElement | null>();
  const [isExpanded, setIsExpanded] = useState(false);

  const htmlBody = parse(body);

  useLayoutEffect(() => {
    if (postsRef.current && postsRef.current.offsetHeight > MAX_POST_HEIGHT) {
      setIsExpanded(true);
    }
  }, []);

  function onClick() {
    const link = appStore.getUpdateLink(id);

    window.parent.window.location.replace(link);
  }

  return (
    <div
      className="post"
      ref={(element: HTMLDivElement) => (postsRef.current = element)}
      style={{ maxHeight: isExpanded ? '400px' : '100%' }}
    >
      <div className="post_header">
        <div className="post_header_person">
          <Avatar size={Avatar.sizes.MEDIUM} ariaLabel={personName} src={personImage} type={Avatar.types.IMG} />
          <span>{personName}</span>
        </div>
        <div className="post_header_metadata">
          <TimeView createTime={createTime} />
          <Icon
            className="post_header_ext-button"
            ignoreFocusStyle
            onClick={onClick}
            iconType={Icon.type.SRC}
            icon={ExternalPage}
          />
        </div>
      </div>
      <div className="post_body">{htmlBody}</div>
      {isExpanded && (
        <div className="post_button-wrapper">
          <Button
            className="post_button"
            kind={Button.kinds.TERTIARY}
            rightIcon={DropdownChevronDown}
            onClick={() => setIsExpanded(false)}
          >
            Read More
          </Button>
        </div>
      )}
      {Boolean(replies.length) && <RepliesList replies={replies} />}
    </div>
  );
};

interface PostData {
  personName: string;
  personImage: string;
  createTime: string;
  body: string;
  replies: Reply[];
  id: string;
}
