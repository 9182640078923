import { Creator, SelectedDate, UpdateData } from '../types';
import { markText } from '../utils';

export function filterBySearchValue(updates: UpdateData[], searchValue: string) {
  if (!searchValue) return updates;

  const highlightedMatches = updates.reduce((filteredUpdates, update) => {
    const { body, replies, textBody } = update;

    let isMet =
      isTextIncluded(textBody, searchValue) || replies.some((reply) => isTextIncluded(reply.textBody, searchValue));

    if (isMet) {
      const markedBody = markText(body, searchValue);
      const markedReplies = replies.map((reply) => ({ ...reply, body: markText(reply.body, searchValue) }));
      filteredUpdates.push({ ...update, body: markedBody, replies: markedReplies });
    }

    return filteredUpdates;
  }, [] as UpdateData[]);

  return highlightedMatches;
}

export function filterByUser(updates: UpdateData[], selectedUsers: Creator[]) {
  if (!selectedUsers.length) return updates;

  const filteredByUser = updates.filter((update) => {
    const isUpdateCreator = selectedUsers.find((user) => user.id === Number(update.creator.id));
    const isReplyCreator = update.replies.some((reply) => {
      return selectedUsers.find((user) => user.id === Number(reply.creator.id));
    });

    return isUpdateCreator || isReplyCreator;
  });

  return filteredByUser;
}

export function filterByDate(updates: UpdateData[], selectedDate: SelectedDate) {
  if (!selectedDate.startDate) return updates;

  const filteredByDate = updates.filter((update) => {
    const { startDate, endDate } = selectedDate;

    if (endDate) {
      return (
        update.createdAt >= startDate!.startOf('day').toISOString() &&
        update.createdAt <= endDate.endOf('day').toISOString()
      );
    }

    return update.createdAt >= startDate!.startOf('day').toISOString();
  });

  return filteredByDate;
}

function isTextIncluded(text: string, searchValue: string): boolean {
  return text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
}
