import { action, makeAutoObservable, computed, observable, runInAction } from 'mobx';
import {
  getContext,
  getItemUpdatesGradually,
  getSlug,
  getPreparedUpdates,
  filterBySearchValue,
  filterByUser,
  filterByDate,
  getUniqueUsers,
  EVENT_ACTIONS,
  analytics,
} from '../services';
import { MESSAGE_TYPES } from '../constants';
import { Creator, SelectedDate, UpdateData } from '../types';

class AppStore {
  constructor() {
    makeAutoObservable(this);
  }

  boardId!: number;
  itemId!: number;
  slug: string = 'monday';

  @observable updates: UpdateData[] = [];
  @observable loading: boolean = true;
  @observable error = { message: '', type: MESSAGE_TYPES.ERROR };

  @observable searchValue: string = '';
  @observable users: Creator[] = [];
  @observable selectedUsers: Creator[] = [];
  @observable selectedDate: SelectedDate = { startDate: null, endDate: null };

  @action
  async init() {
    try {
      const { boardId, itemId, user } = (await getContext()).data;
      const { isViewOnly } = user;

      if (isViewOnly) {
        throw new Error('As a viewer, you are unable to use this app.');
      }

      const updates = await getItemUpdatesGradually(itemId);
      const slug = await getSlug(boardId);

      const preparedUpdates = getPreparedUpdates(updates);
      const users = getUniqueUsers(updates);

      runInAction(() => {
        this.loading = false;
        this.boardId = boardId;
        this.itemId = itemId;
        this.updates = preparedUpdates;
        this.slug = slug || this.slug;
        this.users = users;
      });
    } catch (error: any) {
      runInAction(() => {
        this.error.message = error.message;
      });
    } finally {
      this.loading = false;
    }
  }

  @action
  setSearchValue(value: string) {
    this.searchValue = value;
  }

  @action
  setSelectedUser(id: string) {
    const selectedUser = this.users.find((user) => user.id === Number(id));

    if (!selectedUser) return;

    this.selectedUsers = [ ...this.selectedUsers, selectedUser ];
  }

  @action
  setSelectedDate(date: SelectedDate) {
    this.selectedDate = date;
  }

  @action
  removeSelectedUser(id: string) {
    if (!id) return;

    this.selectedUsers = this.selectedUsers.filter((user) => user.id !== Number(id));
  }

  @action
  getUpdateLink(id: string) {
    return `https://${this.slug}.monday.com/boards/${this.boardId}/pulses/${this.itemId}/posts/${id}`;
  }

  @action
  setErrorType(type: MESSAGE_TYPES) {
    this.error.type = type;
  }

  @computed
  get filteredUpdates() {
    if (!this.searchValue && !this.selectedUsers.length && !this.selectedDate.startDate) return this.updates;

    const filteredByValueUpdates = filterBySearchValue(this.updates, this.searchValue);
    const filteredByPersonUpdates = filterByUser(filteredByValueUpdates, this.selectedUsers);
    const filteredByDateUpdates = filterByDate(filteredByPersonUpdates, this.selectedDate);

    analytics.sendEvent(EVENT_ACTIONS.SEARCH_EXECUTED, { boardId: this.boardId });
    analytics.sendEvent(EVENT_ACTIONS.GENERAL, { boardId: this.boardId });

    return filteredByDateUpdates;
  }

  @computed
  get isAppDisabled(): boolean {
    return this.loading || !!this.error.message;
  }
}

export const appStore = new AppStore();
