import React from 'react';
import { Avatar } from 'monday-ui-react-core';
import './OptionsRenderer.scss';

type OptionRendererProps = { src: string; label: string };

export const OptionRenderer: React.FC<OptionRendererProps> = ({ src, label }) => {
  return (
    <div className="inline-container" key={label}>
      <Avatar size={Avatar.sizes.SMALL} src={src} type={Avatar.types.IMG} key={label} />
      <span className="name">{label}</span>
    </div>
  );
};
