import { TextNodePosition, getTextNodes } from './html-parser';

export function makeLinksExternal(text: string): string {
  // RegExp for finding all tags a and add target attribute
  const regex = new RegExp('<(a )([^>]+)>', 'gi');

  return text.replaceAll(regex, '<$1target="_blank" rel="noopener noreferrer" $2>');
}

export function markText(text: string, _searchValue: string): string {
  const clearText = text.replace(/\uFEFF/g, '');
  const searchValue = _searchValue.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;');

  const nodes = getTextNodes(clearText);
  const textNodePositions = getTextNodePositions(nodes, searchValue);
  const highlightedText = highlightText(textNodePositions, clearText);

  return highlightedText;
}

function getTextNodePositions(nodes: TextNodePosition[], searchValue: string) {
  const allText = nodes.reduce((acc: string, node: TextNodePosition) => {
    acc += node.text;
    return acc;
  }, '');

  const firstIndex = allText.toLowerCase().indexOf(searchValue.toLowerCase());
  const lastIndex = firstIndex + searchValue.length;
  const results = [];
  let nodeLastIndex = 0;
  let nodeFirstIndex = 0;
  let isFirstTouch = true;

  for (const { text, position } of nodes) {
    nodeLastIndex += text.length;
    const isLastNode = nodeLastIndex + 1 > lastIndex;

    if (nodeLastIndex <= firstIndex) {
      nodeFirstIndex += text.length;
      continue;
    }

    if (firstIndex !== -1) {
      const endIndexSlice = isLastNode ? lastIndex - nodeFirstIndex : text.length;
      const startIndexSlice = isFirstTouch ? firstIndex - nodeFirstIndex : 0;
      const word = text.slice(startIndexSlice, endIndexSlice);
      const textNodePosition = isFirstTouch ? position + firstIndex - nodeFirstIndex : position;

      results.push({ text: word, position: textNodePosition });
      isFirstTouch = false;
    }

    if (isLastNode) {
      break;
    }

    nodeFirstIndex += text.length;
  }

  return results;
}

function highlightText(nodes: TextNodePosition[], htmlStr: string) {
  const sortedNodes = [...nodes].reverse();

  const startTag = `<mark>`;
  const endTag = `</mark>`;

  const highlightedText = sortedNodes.reduce((str: string, { text, position }) => {
    const [beforeText, afterText] = [str.slice(0, position), str.slice(position + text.length)];
    str = [beforeText, startTag, text, endTag, afterText].join('');

    return str;
  }, htmlStr);

  return highlightedText;
}
