import mondaySdk from 'monday-sdk-js';
import { GET_UPDATES_QUERY_COMPLEXITY } from '../constants';
import { getItemUpdatesProp, MondayComplexity, MondayUpdates, UpdateData, UserInfoData, WidgetContext } from '../types';
import { delay, toCamelCase } from '../utils';

import axios from 'axios';

const APP_NAME = 'update_searcher';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_LAMBDA_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const monday = mondaySdk();

export async function getUserInfo(token: string): Promise<UserInfoData> {
  try {
    const { data } = await apiClient.post('/auth', {
      token,
      name: APP_NAME,
    });

    return toCamelCase(data);
  } catch (error: any) {
    console.error('Authentication error', { error });

    throw error;
  }
}

export async function getContext(): Promise<WidgetContext> {
  return await new Promise((resolve) => monday.listen('context', (data) => resolve(data as unknown as WidgetContext)));
}

async function getItemUpdates({ itemId, limit, page }: getItemUpdatesProp): Promise<UpdateData[]> {
  const query = `query ( $itemId: [Int],  $limit: Int, $page: Int ) {
     items (ids: $itemId) {
      id
      updates (limit: $limit, page: $page) {
        id
        body
        created_at
        text_body
        replies {
          id
          body
          text_body
          created_at
          creator {
            id
            name
            photo_thumb_small
          }
        }
        creator {
          id
          name
          photo_thumb_small
        }
      }
     }

  }`;

  const variables = { itemId, limit, page };
  const result = toCamelCase(await monday.api(query, { variables })) as MondayUpdates;

  return result.data.items[ 0 ].updates;
}

export async function getItemUpdatesGradually(itemId: number): Promise<UpdateData[]> {
  const UPDATES_LIMIT = 100;
  const result = [];
  let page = 1;

  while ((page - 1) * UPDATES_LIMIT === result.length) {
    try {
      await checkEnoughComplexity();
      const items = await getItemUpdates({ itemId, limit: UPDATES_LIMIT, page });

      result.push(...items);
      page++;
    } catch (error) {
      console.log('Error while getting items', { error });
    }
  }

  return result;
}

export async function getSlug(boardId: number) {
  const query = `query ($boardId: [Int]) {
    boards(ids: $boardId) {
      owner {
        account {
          slug
        }
      }
    }
  }`;

  const variables = { boardId };
  const result = (await monday.api(query, { variables })) as {
    data: { boards: { owner: { account: { slug: string } } }[] };
  };

  return result.data.boards[ 0 ].owner.account.slug;
}

export async function getMaxUsers() {
  const query = `query {
    account {
      plan {
        max_users
      }
    }
  }`;
  type QueryResponse = { data: { account: { plan: { max_users: number } } } };
  const response = await monday.api(query) as QueryResponse;

  return response.data.account.plan.max_users;
}

export async function getUsers() {
  const query = `query {
    users {
      id
      email
    }
  }`;

  const result = (await monday.api(query)) as { data: { users: { id: number; email: string }[] } };

  return result.data.users;
}

async function checkEnoughComplexity() {
  const { complexity } = await getComplexity();
  const { before, resetInXSeconds } = complexity;

  if (before < GET_UPDATES_QUERY_COMPLEXITY) {
    await delay(resetInXSeconds * 1000);
  }
}

async function getComplexity() {
  const query = `query {
      complexity {
        before
        reset_in_x_seconds
      }
    }`;
  const result = toCamelCase(await monday.api(query, {})) as MondayComplexity;
  return result.data;
}

export async function openPlanSelectionModal(isInPlanSelection: boolean) {
  //@ts-ignore
  return await monday.execute('openPlanSelection', { isInPlanSelection });
}

export function mondayValueCreated() {
  monday.execute('valueCreatedForUser');
}
