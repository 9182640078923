"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const NoColor = (_a) => {
    var { size } = _a, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ viewBox: "0 0 20 20", fill: "currentColor", width: size || "20", height: size || "20" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M9.99869 2.45479C10.29 2.1796 10.7462 2.18205 11.0346 2.46035 11.8801 3.27645 12.629 4.18707 13.2665 5.17427 13.4912 5.52224 13.3913 5.98648 13.0433 6.21118 12.6953 6.43589 12.2311 6.33596 12.0064 5.98799 11.5642 5.30318 11.0625 4.65922 10.5075 4.06347 8.55502 6.10494 6.79968 9.10384 6.79968 12.2306 6.79968 12.6448 6.4639 12.9806 6.04968 12.9806 5.63547 12.9806 5.29968 12.6448 5.29968 12.2306 5.29968 8.25797 7.68621 4.63918 9.99869 2.45479zM14.0817 8.2783C14.4692 8.13179 14.902 8.3271 15.0485 8.71454 15.4712 9.83227 15.7016 11.0135 15.7298 12.2081 15.7548 12.9019 15.6367 13.5934 15.3829 14.2396 15.1285 14.8874 14.7432 15.4758 14.251 15.9679 13.7589 16.4601 13.1705 16.8454 12.5227 17.0998 11.881 17.3518 11.1947 17.47 10.5059 17.4472 9.21585 17.4632 7.96422 17.0068 6.98717 16.1636 6.67359 15.893 6.63877 15.4194 6.9094 15.1058 7.18003 14.7922 7.65362 14.7574 7.9672 15.028 8.67056 15.635 9.57259 15.9622 10.5015 15.9471 10.5148 15.9469 10.5281 15.9471 10.5414 15.9476 11.0307 15.9657 11.5186 15.8826 11.9744 15.7036 12.4302 15.5246 12.8441 15.2535 13.1904 14.9073 13.5366 14.561 13.8077 14.1471 13.9867 13.6913 14.1657 13.2355 14.2487 12.7477 14.2306 12.2583L14.2303 12.248 14.2303 12.248C14.2065 11.2211 14.0088 10.2058 13.6455 9.2451 13.499 8.85766 13.6943 8.42481 14.0817 8.2783zM17.3142 3.38282C17.6071 3.67571 17.6071 4.15058 17.3142 4.44348L4.53033 17.2274C4.23744 17.5203 3.76256 17.5203 3.46967 17.2274 3.17678 16.9345 3.17678 16.4596 3.46967 16.1667L16.2536 3.38282C16.5465 3.08992 17.0214 3.08992 17.3142 3.38282z", fill: "currentColor", fillRule: "evenodd", clipRule: "evenodd" }) })));
};
NoColor.displayName = 'NoColor';
exports.default = NoColor;
