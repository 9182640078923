export interface TextNodePosition {
  position: number;
  text: string;
}

const regExp = /<([a-zA-Z][^<>"']*("|'[^']*'|[^'"<>])*|\/[a-zA-Z][^<>"']*)>/gi; // RegExp to match valid HTML tags

export function getTextNodes(htmlStr: string) {
  const textNodes: TextNodePosition[] = [];
  let lastIndex = 0;

  while (true) {
    // Get index of a matched element/tag if exist
    const matchIndex = regExp.exec(htmlStr)?.index;
    if (matchIndex === undefined) {
      break;
    }

    if (matchIndex > lastIndex) {
      const text = htmlStr.slice(lastIndex, matchIndex);
      textNodes.push({ text, position: lastIndex });
    }
    lastIndex = regExp.lastIndex;
  }

  // Add text after last matched tag. Example: hello<b>wo</b>rld => Code bellow allows us to include "rld" after </b>
  if (lastIndex < htmlStr.length) {
    const text = htmlStr.slice(lastIndex);
    textNodes.push({ text, position: lastIndex });
  }

  return textNodes;
}
