import { UpdateData } from '../types';
import { getSanitizedText, makeLinksExternal } from '../utils';

export function getPreparedUpdates(updates: UpdateData[]) {
  const preparedUpdates = updates.map((update) => {
    const replies = update.replies;
    const modifiedUpdateBody = modifyHtmlBody(update.body);

    const sanitizedReplies = replies.map((reply) => {
      const modifiedReplyBody = modifyHtmlBody(reply.body);

      return { ...reply, body: modifiedReplyBody };
    });

    return { ...update, body: modifiedUpdateBody, replies: sanitizedReplies };
  });

  return preparedUpdates;
}

function modifyHtmlBody(body: string) {
  const sanitizedReplyBody = getSanitizedText(body);
  const replyBodyWithExtLinks = makeLinksExternal(sanitizedReplyBody);

  return replyBodyWithExtLinks;
}
