import React from 'react';
import { ERRORS_CONFIG, MESSAGE_TYPES } from '../../constants';
import { Button } from 'monday-ui-react-core';
import { openPlanSelectionModal } from '../../services';
import cn from 'classnames';
import './InfoMessage.scss';

export const InfoMessage: React.FC<InfoMessageData> = ({ message, type = MESSAGE_TYPES.INFO }) => {
  const { buttonMessage, isInPlanSelection } = ERRORS_CONFIG[type] || {};
  const isInfoMessage = type === MESSAGE_TYPES.INFO;

  async function onClick() {
    await openPlanSelectionModal(isInPlanSelection);
  }

  return (
    <div className="message">
      <h2
        className={cn({
          error: !isInfoMessage,
        })}
      >
        {message}
      </h2>
      {!!buttonMessage && (
        <div>
          <Button onClick={onClick}>{buttonMessage}</Button>
        </div>
      )}
    </div>
  );
};

interface InfoMessageData {
  message: string;
  type?: MESSAGE_TYPES;
}
