import React from 'react';
import { Avatar } from 'monday-ui-react-core';
import { Reply } from '../../types';
import parse from 'html-react-parser';
import { TimeView } from '../TimeView/TimeView';
import './RepliesList.scss';

export const RepliesList: React.FC<{ replies: Reply[] }> = ({ replies }) => {
  return (
    <div className="replies-list">
      {replies.map(({ creator, body, createdAt, id }) => {
        const htmlBody = parse(body);
        return (
          <div className="replies-list_item" key={id}>
            <Avatar
              ariaLabel={creator.name}
              size={Avatar.sizes.MEDIUM}
              src={creator.photoThumbSmall}
              type={Avatar.types.IMG}
            />

            <div className="replies-list_aside">
              <div className="replies-list_body">
                <span className="replies-list_name">{creator.name}</span>
                <div>{htmlBody}</div>
              </div>
              <TimeView createTime={createdAt} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
