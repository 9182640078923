import React, { useCallback, useRef, useState } from 'react';
import { appStore } from '../../stores/app.store';
import { Icon, Button, DialogContentContainer, DatePicker } from 'monday-ui-react-core';
import { observer } from 'mobx-react-lite';
import { Calendar } from 'monday-ui-react-core/dist/allIcons';
import { SelectedDate } from '../../types';
import { useClickOutside } from 'monday-ui-react-core';
import './DateFilter.scss';

export const DateFilter: React.FC = observer(() => {
  const { selectedDate, isAppDisabled } = appStore;
  const [isOpen, setIsOpen] = useState(false);
  const filterWrapperRef = useRef<HTMLDivElement | null>(null);

  function onIconClick() {
    setIsOpen(!isOpen);
  }

  function onDateChange(date: SelectedDate) {
    appStore.setSelectedDate(date);
  }

  function resetDate() {
    appStore.setSelectedDate({ startDate: null, endDate: null });
    setIsOpen(false);
  }

  const onClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useClickOutside({
    ref: filterWrapperRef,
    callback: onClick,
  });

  return (
    <div className="date-filter" ref={filterWrapperRef}>
      <div className="date-filter_button-wrapper">
        <Button
          onClick={onIconClick}
          disabled={isAppDisabled}
          kind={Button.kinds.TERTIARY}
          active={selectedDate.startDate}
          padding={4}
        >
          <Icon ignoreFocusStyle icon={Calendar} iconSize={30} />
        </Button>
      </div>

      {isOpen && (
        <DialogContentContainer className="date-filter_dialog-container">
          <span onClick={resetDate} className="date-filter_reset">
            Clear
          </span>
          <DatePicker date={selectedDate.startDate} endDate={selectedDate.endDate} range onPickDate={onDateChange} />
        </DialogContentContainer>
      )}
    </div>
  );
});
