import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { appStore } from '../../stores/app.store';
import { mondayValueCreated } from '../../services';
import { InfoMessage, Loader, Post } from '../';
import './PostList.scss';

export const PostList: React.FC = observer(() => {
  const { filteredUpdates, error, loading } = appStore;
  const { message, type } = error;

  useEffect(() => {
    if (!message && !loading) {
      mondayValueCreated();
    }
  }, [loading, message]);

  if (message) {
    return <InfoMessage message={message} type={type} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!filteredUpdates.length) {
    return <InfoMessage message="No updates yet for this item" />;
  }

  return (
    <div className="post-list">
      {filteredUpdates.map(({ creator, createdAt, replies, body, id }) => (
        <Post
          key={id}
          id={id}
          body={body}
          createTime={createdAt}
          personImage={creator.photoThumbSmall}
          personName={creator.name}
          replies={replies}
        />
      ))}
    </div>
  );
});
