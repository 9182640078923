import { DateTime, Duration } from 'luxon';

export function getFullDate(date: string): string {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED, { locale: 'en-GB' });
}

export function getFormattedDate(date: string): string {
  const createdDate = DateTime.fromISO(date);
  const nowDate = DateTime.now();
  const intervalInMs = nowDate.toMillis() - createdDate.toMillis();
  const isSameYear = createdDate.year === nowDate.year;

  const { years, days, hours, minutes } = Duration.fromMillis(intervalInMs)
    .shiftTo('days', 'minutes', 'hours', 'years', 'seconds')
    .toObject();

  if (years || (typeof days === 'number' && days > 28)) {
    return DateTime.fromISO(date).toLocaleString(
      { day: 'numeric', month: 'short', year: isSameYear ? undefined : 'numeric' },
      { locale: 'en-GB' }
    );
  }

  if (days) {
    return `${days}d`;
  }

  if (hours) {
    return `${hours}h`;
  }

  if (minutes) {
    return `${minutes}m`;
  }

  return 'just now';
}
