export const GET_UPDATES_QUERY_COMPLEXITY = 1_600;
export const MAX_POST_HEIGHT = 700;
export const DEBOUNCE_MS = 500;
export const MAX_DAYS_AFTER_EXPIRATION = 45;

export const SUBSCRIPTION_PLANS: Record<string, number> = {
  XXXS: 5,
  XXS: 10,
  XS: 20,
  S: 30,
  M: 40,
  L: 50,
  XL: 100,
  XXL: 200,
  XXXL: 999_999,
};

export enum MESSAGE_TYPES {
  ERROR = 'ERROR',
  INFO = 'INFO',
  NO_SUBSCRIPTION_ERROR = 'NO_SUBSCRIPTION_ERROR',
  SUBSCRIPTION_EXPIRED_ERROR = 'SUBSCRIPTION_EXPIRED_ERROR',
  MAX_ALLOWED_USERS_ERROR = 'MAX_ALLOWED_USERS_ERROR',
}

export const ERRORS_CONFIG: Record<string, { buttonMessage: string; isInPlanSelection: boolean }> = {
  NO_SUBSCRIPTION_ERROR: { buttonMessage: 'Get subscription', isInPlanSelection: true },
  SUBSCRIPTION_EXPIRED_ERROR: { buttonMessage: 'Renew subscription', isInPlanSelection: false },
  MAX_ALLOWED_USERS_ERROR: { buttonMessage: 'Upgrade subscription', isInPlanSelection: false },
};

export const INVISIBLE_SPACE_REGEX = '/\uFEFF/g';
