import React from 'react';
import { PersonFilter, DateFilter } from '../';
import './Filters.scss';

export const Filters: React.FC = () => {
  return (
    <div className="filters">
      <DateFilter />
      <PersonFilter />
    </div>
  );
};
