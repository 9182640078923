import { Loader as MondayLoader } from 'monday-ui-react-core';
import './Loader.scss';

export const Loader = () => {
  return (
    <div className="loader">
      <MondayLoader size={MondayLoader.sizes.LARGE} />
    </div>
  );
};
