import { Creator, UpdateData } from '../types';
import _ from 'lodash';

export function getUniqueUsers(updates: UpdateData[]) {
  const allPersons = getAllUsers(updates);

  return _.uniqBy(allPersons, 'id');
}

function getAllUsers(updates: UpdateData[]) {
  return updates.reduce((users, update) => {
    users.push(update.creator, ...update.replies.map((reply) => reply.creator));

    return users;
  }, [] as Creator[]);
}
