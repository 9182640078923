import React, { useCallback, useRef, useState } from 'react';
import { appStore } from '../../stores/app.store';
import {
  DialogContentContainer,
  Combobox,
  Chips,
  Icon,
  Avatar,
  AvatarGroup,
  Button,
  Counter,
} from 'monday-ui-react-core';
import { observer } from 'mobx-react-lite';
import { Creator } from '../../types';
import { OptionRenderer } from '../OptionsRenderer/OptionsRenderer';
import { PersonRound } from 'monday-ui-react-core/dist/allIcons';
import './PersonFilter.scss';
import { useClickOutside } from 'monday-ui-react-core';

export const PersonFilter: React.FC = observer(() => {
  const { users, selectedUsers, isAppDisabled } = appStore;
  const [isOpen, setIsOpen] = useState(false);
  const filterWrapperRef = useRef<HTMLDivElement | null>(null);

  const usersOption = users
    .filter((user) => !selectedUsers.find((selectedUser) => selectedUser.id === user.id))
    .map(userToOption);

  function onOptionClick(option: MultiPersonOptionProps) {
    appStore.setSelectedUser(option.value);
  }

  function onLabelClick(id: string) {
    appStore.removeSelectedUser(id);
  }

  function onIconClick() {
    setIsOpen(!isOpen);
  }

  const onClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useClickOutside({
    ref: filterWrapperRef,
    callback: onClick,
  });

  return (
    <div className="person-filter" ref={filterWrapperRef}>
      <div className="person-filter_button-wrapper">
        <Button
          onClick={onIconClick}
          disabled={isAppDisabled}
          kind={Button.kinds.TERTIARY}
          active={selectedUsers.length}
          padding={4}
        >
          {selectedUsers.length ? (
            <AvatarGroup
              type={Avatar.types.IMG}
              size={Avatar.sizes.SMALL}
              max={1}
              counterProps={{
                color: Counter.colors.DARK,
                className: 'person-filter_counter',
                maxDigits: 2,
              }}
            >
              {selectedUsers.map((user) => (
                <Avatar size={Avatar.sizes.SMALL} key={user.id} src={user.photoThumbSmall} ariaLabel={user.name} />
              ))}
            </AvatarGroup>
          ) : (
            <Icon ignoreFocusStyle icon={PersonRound} iconSize={30} />
          )}
        </Button>
      </div>

      {isOpen && (
        <DialogContentContainer className="person-filter_dialog-container">
          <div className="person-filter_selected">
            {selectedUsers.map((user) => (
              <Chips
                key={user.id}
                leftAvatar={user.photoThumbSmall}
                label={user.name}
                id={user.id}
                onDelete={onLabelClick}
              />
            ))}
          </div>
          <Combobox
            size={Combobox.sizes.SMALL}
            onClick={onOptionClick}
            options={usersOption}
            placeholder="Search"
            optionRenderer={OptionRenderer}
          />
        </DialogContentContainer>
      )}
    </div>
  );
});

function userToOption(user: Creator | null) {
  if (!user) return null;

  return {
    value: String(user.id),
    label: user.name,
    src: user.photoThumbSmall,
  };
}

type MultiPersonOptionProps = {
  value: string;
  label: string;
  src: string;
};
