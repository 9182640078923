import React, { useEffect } from 'react';
import { setTheme } from '../../utils';
import { appStore } from '../../stores/app.store';
import { monday, EVENT_ACTIONS, analytics } from '../../services';
import { Filters, PostList, Searchbar } from '..';
import { observer } from 'mobx-react-lite';
import 'monday-ui-react-core/dist/main.css';
import './App.scss';

export const App = observer(() => {
  useEffect(() => {
    monday.listen('context', (res: { data: any }) => {
      const { theme } = res.data;
      setTheme(theme);
    });
  });

  useEffect(() => {
    (async () => {
      await appStore.init();
      analytics.sendEvent(EVENT_ACTIONS.USED_ON_BOARD, { boardId: appStore.boardId });
    })();
  }, []);

  return (
    <div className="app">
      <Searchbar />
      <Filters />
      <PostList />
    </div>
  );
});
