import React from 'react';
import { TextField } from 'monday-ui-react-core';
import { appStore } from '../../stores/app.store';
import { CloseSmall } from 'monday-ui-react-core/dist/allIcons';
import { observer } from 'mobx-react-lite';
import { DEBOUNCE_MS } from '../../constants';
import './Searchbar.scss';

export const Searchbar: React.FC = observer(() => {
  const { searchValue, isAppDisabled } = appStore;
  function onChange(value: string) {
    appStore.setSearchValue(value);
  }

  function onIconClick() {
    setTimeout(() => {
      appStore.setSearchValue('');
    }, DEBOUNCE_MS);
  }

  return (
    <div className="searchbar">
      <TextField
        placeholder="Search"
        value={searchValue}
        iconName={CloseSmall}
        onIconClick={onIconClick}
        onChange={onChange}
        debounceRate={DEBOUNCE_MS}
        disabled={isAppDisabled}
        size={TextField.sizes.MEDIUM}
      />
    </div>
  );
});
