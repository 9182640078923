import React from 'react';
import { Icon, Tooltip } from 'monday-ui-react-core';
import { Time } from 'monday-ui-react-core/dist/allIcons';
import { getFormattedDate, getFullDate } from '../../utils';
import './TimeView.scss';

export const TimeView: React.FC<{ createTime: string }> = ({ createTime }) => {
  const tooltipDate = getFullDate(createTime);
  const displayDate = getFormattedDate(createTime);

  return (
    <Tooltip content={tooltipDate}>
      <div className="time">
        <Icon iconType={Icon.type.SRC} icon={Time} ignoreFocusStyle />
        <span>{displayDate}</span>
      </div>
    </Tooltip>
  );
};
