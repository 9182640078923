import ReactGA from 'react-ga4';

export const EVENT_ACTIONS = {
  USED_ON_BOARD: 'used_on_board',
  SEARCH_EXECUTED: 'search_executed',
  GENERAL: 'update_searcher_search_executed',
} as const;

type EventAction = (typeof EVENT_ACTIONS)[keyof typeof EVENT_ACTIONS];

class Analytics {
  private appMeasurementId: string;
  private generalMeasurementId: string;

  constructor(appMeasurementId: string, generalMeasurementId: string) {
    this.appMeasurementId = appMeasurementId;
    this.generalMeasurementId = generalMeasurementId;

    ReactGA.initialize([
      {
        trackingId: appMeasurementId,
        gaOptions: { cookieFlags: 'SameSite=None;Secure' },
      },
      {
        trackingId: generalMeasurementId,
        gaOptions: { cookieFlags: 'SameSite=None;Secure' },
      },
    ]);
  }

  sendEvent(eventAction: EventAction, config: object) {
    const measurementId = eventAction === EVENT_ACTIONS.GENERAL ? this.generalMeasurementId : this.appMeasurementId;
    ReactGA.gtag('event', eventAction, { ...config, send_to: measurementId });
  }
}

export const analytics = new Analytics(
  process.env.REACT_APP_GA_MEASUREMENT_ID!,
  process.env.REACT_APP_GA_GENERAL_MEASUREMENT_ID!
);
